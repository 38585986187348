
<div class="background">
    <!--<form #orderForm="ngForm" novalidate autocomplete="off" (ngSubmit)="save()" class="w-100">-->
    <form #orderForm="ngForm" novalidate autocomplete="off" class="w-100">
        <div class="h-100 w-100">
            <div class="d-flex align-items-center justify-content-center w-100 header-container">
                <div class="row top-row">
                    <div class="col-lg-12">
                        <div class="logo-fundraiser-name row">
                            <div class="hour-a-thon-org-logo-container col-4 col-sm-3 col-md-2 my-auto">
                                <img src="{{logoUrl}}" alt="logo" class="hour-a-thon-logo">
                            </div>
                            <img src="/assets/common/images/circle-mask-3.svg" alt="logo" class="hour-a-thon-logo logo-mask" />
                            <div class="col-8 col-sm-9 col-md-8 hour-a-thon-fundraiser-name yellow-text">
                                <div class="d-flex flex-column">
                                    <div class="hour-a-thon-organization-name brush-stroke">{{organizationName}}</div>
                                    <div class="fundraiser-name-and-powered-by-container">
                                        <span class="fundraiser-name">{{fundraiserName}}</span>
                                        <div class="powered-by-container">
                                            <img alt="Fundraiser Group Logo" src="{{tenantLogoUrl}}" class="powered-by-logo" />
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="teamMemberNameInput">
                                    <span class="powered-by-text">{{l('OnBehalfOf')}} </span><span class="brush-stroke yellow-text student-progress-footer-name">{{teamMemberNameInput}}</span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mb-40 overlay d-flex justify-content-center">
                <div class="row middle-row" style="max-width: 1000px;">

                    <div *ngIf="visible" class="donation-page-root">
                        <h1 class="mb-5">
                            {{l('DonationsPageHeader')}}
                        </h1>
                        <ng-container *ngIf="allowRoundUp">
                            <div class="donations-page-roundto">
                                <div class="mb-3">
                                    {{l('DonationsPageRoundTo')}}
                                </div>
                                <div class="d-flex align-items-center justify-content-center row" role="group">
                                    <div *ngFor="let amt of donationAmounts" class="col-4 col-md-3 col-lg-2 ">
                                        <button type="button" (click)="setSelectedAmount(amt)"
                                                class="customer-button btn font-size-1-5 donation-button w-100"
                                                [ngClass]="amt===selectedDonation?'enabled-background customer-submit-button ':'btn-primary'">
                                            {{amt | currency: 'USD' : 'symbol' : '1.0-0'}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-1 mb-1">
                                <div class="or-underline">OR</div>
                            </div>
                        </ng-container>
                        <div class="donations-page-addon">
                            <div class="mb-1">
                                {{l('DonationsPageAddOn')}}
                            </div>
                            <div class="input-group donations-page-center-form">
                                <span class="btn btn-primary font-size-1-5">$</span>
                                <input type="number" [(ngModel)]="customDonation" (change)="setCustomDonation()" name="CustomDonation" min="10" step="1" data-number-to-fixed="2" data-number-stepfactor="100" class="form-control currency font-size-1-5" />
                                <div *ngIf="isDonationInvalid" class="validation-error">{{l('DonationMustBeAtLeastTenDollars')}}</div>
                            </div>
                        </div>
                        <div *ngIf="showTipInput" class="donations-page-addon">
                            <div class="mb-1">
                                {{l('EditTipPercentage')}}
                            </div>
                            <div class="input-group donations-page-center-form">
                                <input type="number" [(ngModel)]="updatedTipPercentage" name="UpdatedTipPercentage" min="0" step="1" data-number-stepfactor="1" class="form-control currency" />
                            </div>
                        </div>
                        <div class="donations-page-footer">
                            <p>
                                <button *ngIf="selectedDonation > 0" (click)="setDonation()" class="customer-button customer-submit-button enabled-background wide-button">{{l('RoundOrderToButton', selectedDonation)}}</button>
                                <button *ngIf="!selectedDonation && customDonation" (click)="setDonation()" class="customer-button customer-submit-button enabled-background wide-button" [disabled]="isDonationInvalid"
                                        [ngClass]="isDonationInvalid ? 'disabled-background' : 'enabled-background'">{{l('AddDonation', customDonation)}}</button>
                            </p>
                            <div class="d-flex align-items-center justify-content-center row pb-1">
                                <button type="button" class="customer-button customer-cancel-button go-back-button" (click)="setDonation(false)">
                                    {{l("GoBack")}}
                                </button>
                                <button class="customer-button customer-cancel-button wide-button" type="button" (click)="noDonation()">
                                    {{l('DonationNoThanks')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="live-link-footer">
                <div class="d-flex flex-row justify-content-center w-100">
                    <div class="footer-organization-logo-container my-auto">
                        <img src="{{tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
                    </div>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Contact Us</a>
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Run your own Fundraiser</a>
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Join our team</a>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a (click)="openInNewTab(appUrlService.appRootUrl + 'TermsOfUse.html')">Terms of Use</a>
                </div>
                <div class="d-flex flex-row justify-content-center w-100">
                    <div class="yellow-text d-flex align-items-end">
                        <div class="d-flex flex-column">
                            <div class="fundraiser-name-and-powered-by-container">
                                <div class="powered-by-container">
                                    <div class="powered-by-text">{{l('PoweredBy')}}</div>
                                    <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </form>
</div>