<div [@routerTransition] class="background">
    <form #orderForm="ngForm" novalidate autocomplete="off" (ngSubmit)="save()" class="w-100">
        <div class="h-100 w-100" style="background-color: white;">
            <div class="d-flex align-items-center justify-content-center w-100 header-container">
                <div class="row top-row">
                    <div class="col-lg-12">
                        <div class="logo-fundraiser-name row">
                            <div class="hour-a-thon-org-logo-container col-4 col-sm-3 col-md-2 my-auto">
                                <img src="{{logoUrl}}" alt="logo" class="hour-a-thon-logo">
                            </div>
                            <img src="/assets/common/images/circle-mask-3.svg" alt="logo" class="hour-a-thon-logo logo-mask" />
                            <div class="col-8 col-sm-9 col-md-8  hour-a-thon-fundraiser-name yellow-text">
                                <div class="d-flex flex-column">
                                    <div class="hour-a-thon-organization-name brush-stroke">{{organizationName}}</div>
                                    <div class="fundraiser-name-and-powered-by-container">
                                        <span class="fundraiser-name">{{fundraiserName}}</span>
                                        <div class="powered-by-container">
                                            <img alt="Fundraiser Group Logo" src="{{tenantLogoUrl}}" class="powered-by-logo" />
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="teamMemberNameInput">
                                    <span class="powered-by-text">{{l('OnBehalfOf')}} </span><span class="brush-stroke yellow-text student-progress-footer-name">{{teamMemberNameInput}}</span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-40 overlay d-flex justify-content-center" style="background-color: white;">
                <div class="row middle-row">

                    <!-- saving/processing order -->
                    <div *ngIf="isProcessingOrder">
                        <div class="text-center p-5">
                            <h2 class="heading-white">{{l('PleaseWait')}}</h2>
                            <img class="busy-dots" alt="Order is being processed" src="/assets/common/images/busy-dots.gif" />
                            <p class="processing-text">{{l('OrderIsBeingProcessed')}}</p>
                            <p class="processing-text">{{l('DontRefresh')}}</p>
                            <div id="processing-order-spinner"></div>
                        </div>
                    </div>

                    <div *ngIf="!isProcessingOrder && orderDetail" class="h-100 w-100 d-flex flex-column justify-content-center form-parent">
                        <div class="headline">{{l('CheckOut')}}</div>

                        <span class="subheading w-100 text-center mt-5">{{l('OrderInformation')}}</span>
                        <div class="col-12 d-flex">
                            <div class="row inputs-column">

                                <ng-container *ngIf="!teamMemberNameInput">
                                    <div class="col-12 col-md-6" [ngClass]="teamMemberId == -1 ? '' : 'mb-3 mb-md-4'">
                                        <div class="hide-on-mobile">
                                            <div *ngIf="teamMemberId">{{l("OnBehalfOf")}}</div>
                                        </div>
                                        <select #teamMemberSelect="ngModel" name="{{l('OnBehalfOf')}}"
                                                class="form-control contact-field-short"
                                                [(ngModel)]="teamMemberId" required>
                                            <option [ngValue]="undefined" disabled>{{l("OnBehalfOf")}}</option>
                                            <option [value]="-1">Other</option>
                                            <option *ngFor="let teamMember of availableSellers" [value]="teamMember.id">{{teamMember.name}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="teamMemberSelect"></validation-messages>
                                    </div>
                                    <div class="col-6 custom-seller-placeholder"></div>

                                    <div class="col-6 mb-3 mb-md-4" *ngIf="teamMemberId==-1">
                                        <div>
                                            <div *ngIf="teamMemberFirstName" class="hide-on-mobile">{{l("TeamMemberFirstName")}}</div>
                                        </div>
                                        <input #teamMemberFirstNameInput="ngModel" name="{{l('TeamMemberFirstName')}}"
                                               type="text" [(ngModel)]="teamMemberFirstName" placeholder="{{l('TeamMemberFirstName')}}"
                                               required min="1" max="100" data-lpignore="true" />
                                        <validation-messages [formCtrl]="teamMemberFirstNameInput"></validation-messages>
                                    </div>
                                    <div class="col-6 mb-3 mb-md-4" *ngIf="teamMemberId==-1">
                                        <div>
                                            <div *ngIf="teamMemberLastName" class="hide-on-mobile">{{l("TeamMemberLastName")}}</div>
                                        </div>
                                        <input #teamMemberLastNameInput="ngModel" name="{{l('TeamMemberLastName')}}"
                                               type="text" [(ngModel)]="teamMemberLastName" placeholder="{{l('TeamMemberLastName')}}"
                                               required min="1" max="100" data-lpignore="true" />
                                        <validation-messages [formCtrl]="teamMemberLastNameInput"></validation-messages>
                                    </div>
                                </ng-container>
                                <div [ngClass]="tipSelection == 'Other' && !orderDetail.id ? 'col-4' : 'col-6'">
                                    <div>
                                        <div *ngIf="donation != null" class="hide-on-mobile">{{l("Donation")}}</div>
                                    </div>
                                    <p-inputNumber #donationAmountInput="ngModel" name="{{l('Donation')}}" inputId="donationAmountInput" id="donationAmountInput"
                                                   [(ngModel)]="donation" mode="currency" currency="USD" locale="en-US"
                                                   placeholder="{{l('Donation')}}" (focusout)="updateTotals()"
                                                   ngDefaultControl class="form-control">
                                    </p-inputNumber>
                                    <span *ngIf="!isDonationValid" class="validation-error">{{l('DonationMustBeAtLeastTenDollars')}}</span>
                                </div>
                                <ng-container *ngIf="!orderDetail.id && this.isGuest">
                                    <div [ngClass]="tipSelection == 'Other' ? 'col-4' : 'col-6'">
                                        <div class="hide-on-mobile">
                                            <div class="d-inline-block">{{l("Tip")}}&nbsp;</div>
                                            <div (click)="showTipInfo()" class="d-inline-block order-cart-help-circle"
                                                 matTooltip="Info about the action">
                                                <i class="fa fa-question-circle"></i>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-row-auto">
                                            <select name="{{l('Tip')}}"
                                                    class="form-control contact-field-short"
                                                    [(ngModel)]="tipSelection" required>
                                                <option *ngFor="let tipOption of tipOptions" [value]="tipOption">{{tipOption}}</option>
                                            </select>
                                            <div (click)="showTipInfo()" class="order-cart-help-circle show-on-mobile mobile-tip-info"
                                                 matTooltip="Info about the action">
                                                <i class="fa fa-question-circle"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="tipSelection == 'Other'">
                                        <div class="col-4">
                                            <div class="hide-on-mobile">
                                                <div class="d-inline-block">{{l("CustomTip")}}</div>
                                            </div>
                                            <p-inputNumber #donationAmountInput="ngModel" name="{{l('CustomTip')}}" inputId="customTipPercent" id="customTipPercent"
                                                           [(ngModel)]="customTipPercent" suffix="%"
                                                           placeholder="{{l('Tip %')}}"
                                                           ngDefaultControl class="form-control" [min]="0" [max]="100">
                                            </p-inputNumber>
                                            <div *ngIf="!isCustomTipValid" class="validation-error">{{l("TipValueIsInvalid")}}</div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="orderDetail.id">
                                    <div class="col-6">
                                        <div>
                                            <div class="d-inline-block hide-on-mobile">{{l("Tip")}}</div>
                                        </div>
                                        <p-inputNumber #donationAmountInput="ngModel" name="{{l('Tip')}}" inputId="tipDollarsInput" id="tipDollarsInput"
                                                       [(ngModel)]="editOrderTipDollars" mode="currency" currency="USD" locale="en-US"
                                                       placeholder="{{l('Tip')}}" (focusout)="updateTotals()" (ngModelChange)="onTipChange($event)"
                                                       ngDefaultControl class="form-control">
                                        </p-inputNumber>
                                    </div>
                                </ng-container>

                                <div class="subheading w-100 d-flex align-items-center mb-0 mt-5">{{l('BillingInformation')}}</div>
                                <div class="col-6">
                                    <div>
                                        <div *ngIf="firstName" class="hide-on-mobile">{{l('FirstName')}}</div>
                                    </div>
                                    <input #addressBillingFirstNameInput="ngModel" name="{{l('FirstName')}}"
                                           type="text" [(ngModel)]="firstName" placeholder="{{l('FirstName')}}"
                                           required minlength="1" maxlength="128" data-lpignore="true" />
                                    <validation-messages [formCtrl]="addressBillingFirstNameInput"></validation-messages>
                                </div>

                                <div class="col-6">
                                    <div>
                                        <div *ngIf="lastName" class="hide-on-mobile">{{l('LastName')}}</div>
                                    </div>
                                    <input #addressBillingLastNameInput="ngModel" name="{{l('LastName')}}"
                                           type="text" [(ngModel)]="lastName" class="col-6" placeholder="{{l('LastName')}}"
                                           required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" data-lpignore="true" />
                                    <validation-messages [formCtrl]="addressBillingLastNameInput"></validation-messages>
                                </div>
                                <div class="w-100">
                                    <label class="col-12 col-md-6 d-flex align-items-center checkbox-label">
                                        <input name="isAnonymousCheckbox" type="checkbox" [(ngModel)]="orderDetail.isCustomerAnonymous" />
                                        <span class="margin-left-5">{{l('MakeMeAnonymous')}}</span>
                                    </label>
                                </div>

                                <ng-container *ngIf="orderDetail.customerAddress">
                                    <div class="col-6">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.streetLine1" class="hide-on-mobile">{{l("StreetAddressOrPoBox")}}</div>
                                        </div>
                                        <input #streetInput="ngModel" name="{{l('StreetAddressOrPoBox')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.streetLine1" placeholder="{{l('StreetAddressOrPoBox')}}"
                                               required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" data-lpignore="true" />
                                        <validation-messages [formCtrl]="streetInput"></validation-messages>
                                    </div>

                                    <div class="col-3">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.streetLine2">{{l("AddressTwo")}}</div>
                                        </div>
                                        <input #streetInput2="ngModel" name="{{l('AddressTwo')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.streetLine2" placeholder="{{l('AddressTwo')}}"
                                               maxlength="32" data-lpignore="true" />
                                        <validation-messages [formCtrl]="streetInput2"></validation-messages>
                                    </div>

                                    <div class="col-3">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.zipCode" class="hide-on-mobile">{{l("ZipCode")}}</div>
                                        </div>
                                        <input #zipInput="ngModel" name="{{l('ZipCode')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.zipCode" placeholder="{{l('ZipCode')}}"
                                               minlength="5" maxlength="10" required="{{isGuest && isPaymentMethodCreditCard}}" data-lpignore="true" />
                                        <validation-messages [formCtrl]="zipInput"></validation-messages>
                                    </div>

                                    <div class="col-7">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.city" class="hide-on-mobile">{{l("City")}}</div>
                                        </div>
                                        <input #cityInput="ngModel" name="{{l('City')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.city" placeholder="{{l('City')}}"
                                               required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" />
                                        <validation-messages [formCtrl]="cityInput"></validation-messages>
                                    </div>

                                    <div class="col-5">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.stateId" class="hide-on-mobile">{{l("State")}}</div>
                                        </div>
                                        <select #stateSelect="ngModel" name="{{l('State')}}"
                                                class="form-control contact-field-short"
                                                [(ngModel)]="orderDetail.customerAddress.stateId" required="{{isGuest && isPaymentMethodCreditCard}}">
                                            <option [ngValue]="undefined" disabled>State</option>
                                            <option *ngFor="let state of states" [value]="state.id">{{state.displayName}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="stateSelect"></validation-messages>
                                    </div>

                                    <div class="col-12 col-md-5">
                                        <div>
                                            <div>{{l("Phone")}}</div>
                                        </div>
                                        <p-inputMask #phoneInput="ngModel" name="{{l('Phone')}}" [autoClear]="false"
                                                     mask="(999) 999-9999" [(ngModel)]="orderDetail.customerAddress.phoneNumber"
                                                     placeholder="(___) ___-____" ngDefaultControl (onInput)="validatePhoneNumber()" (onBlur)="validatePhoneNumber()"
                                                     class="form-control" data-lpignore="true">
                                        </p-inputMask>
                                        <span *ngIf="phoneErrorMessage" class="validation-error">{{phoneErrorMessage}}</span>
                                    </div>

                                    <div class="col-12 col-md-7">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.emailAddress" class="hide-on-mobile">{{l("Email")}}</div>
                                        </div>
                                        <input #emailInput="ngModel" name="{{l('Email')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.emailAddress" placeholder="{{l('Email')}}"
                                               [required]="(isGuest && isPaymentMethodCreditCard) || orderContainsDGCSelection" maxlength="256" [pattern]="emailAddressPattern" />
                                        <validation-messages [errorDefs]="[{ pattern: l('InvalidEmailAddress') }]"
                                                             [formCtrl]="emailInput"></validation-messages>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!showPaymentOptions && !orderDetail.id">
                                    <div class="subheading w-100 d-flex align-items-center mb-0 mt-5">{{l('DebitOrCredit')}}</div>
                                    <div class="col-7">
                                        <div>
                                            <div *ngIf="orderDetail.creditCardInfo.creditCardNumber" class="hide-on-mobile">{{l('DebitOrCreditCard')}}</div>
                                        </div>
                                        <input #creditCardNumberInput="ngModel" name="{{l('DebitOrCreditCard')}}"
                                               type="text" [(ngModel)]="orderDetail.creditCardInfo.creditCardNumber" placeholder="{{l('DebitOrCreditCard')}}"
                                               required minlength="13" maxlength="20" data-lpignore="true" />
                                        <validation-messages [formCtrl]="creditCardNumberInput"></validation-messages>
                                    </div>
                                    <div class="col-5 pl-2">
                                        <div class="cc-spacer-top"></div>
                                        <div class="credit-card-icons nowrap d-flex">
                                            <i class="fab fa-cc-visa fa-xs text-darker"></i>
                                            <i class="fab fa-cc-mastercard fa-xs text-darker"></i>
                                            <i class="fab fa-cc-discover fa-xs text-darker"></i>
                                            <i class="fab fa-cc-amex fa-xs text-darker"></i>
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div>
                                            <div *ngIf="expiresMonth" class="hide-on-mobile">{{l("Month")}}</div>
                                        </div>

                                        <p-inputMask #expiresMonthInput="ngModel" name="{{l('Month')}}" [autoClear]="false"
                                                     mask="99" max="12" [(ngModel)]="expiresMonth" placeholder="{{l('Month')}}" ngDefaultControl
                                                     (onInput)="validateExpiresMonth()" (onBlur)="validateExpiresMonth()"
                                                     class="form-control contact-field"
                                                     required data-lpignore="true">
                                        </p-inputMask>
                                        <span *ngIf="expiresMonthErrorMessage" class="validation-error">{{expiresMonthErrorMessage}}</span>
                                    </div>


                                    <div class="col-3">
                                        <div>
                                            <div *ngIf="expiresYear" class="hide-on-mobile">{{l("Year")}}</div>
                                        </div>
                                        <p-inputMask #expiresYearInput="ngModel" name="{{l('Year')}}" [autoClear]="false"
                                                     mask="99" [(ngModel)]="expiresYear" placeholder="{{l('Year')}}" ngDefaultControl
                                                     (onInput)="validateExpiresYear()" (onBlur)="validateExpiresYear()"
                                                     class="form-control contact-field"
                                                     required data-lpignore="true">
                                        </p-inputMask>
                                        <span *ngIf="expiresYearErrorMessage" class="validation-error">{{expiresYearErrorMessage}}</span>
                                    </div>


                                    <div class="col-2">
                                        <div class="text-nowrap hide-on-mobile">
                                            <div *ngIf="orderDetail.creditCardInfo.cvv" class="d-inline-block">
                                                {{l("CVVShort")}}&nbsp;
                                            </div>
                                            <a (click)="openInNewTab('https://www.cvvnumber.com/cvv.html')"
                                               class="order-cart-help-circle" rel="noopener noreferrer">
                                                <i class="fa fa-question-circle"></i>
                                            </a>
                                        </div>
                                        <input #cvvInput="ngModel" name="{{l('CVVShort')}}" class="cvv"
                                               type="text" [(ngModel)]="orderDetail.creditCardInfo.cvv" placeholder="{{l('CVVShort')}}"
                                               required minlength="3" maxlength="4" pattern="^[0-9]+$" data-lpignore="true" />
                                        <span *ngIf="!cvvInput.valid && cvvInput.touched"
                                              class="validation-error">{{l("CVVInvalid")}}</span>
                                    </div>
                                </ng-container>

                                <div class="col-4">
                                    <div>
                                        <div class="hide-on-mobile">{{l("Total")}}</div>
                                    </div>
                                    <div class="total">
                                        {{orderTotalsUpdateResult?.orderTotal + tipDollars | currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                </div>

                                <div *ngIf="showPaymentOptions || orderDetail.id" class="mt-5">
                                    <!-- in-person sales can select payment method -->
                                    <ng-container *ngIf="!(isPaymentMethodCreditCard && orderDetail.invoice)">
                                        <div class="mb-3 d-flex flex-center unset-height">
                                            <div class="row student-buttons-row">
                                                <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="(orderDetail.paymentMethodId == paymentMethod.id)?'btn-selected':'btn-default'"
                                                        type="button" *ngFor="let paymentMethod of paymentMethods"
                                                        [hidden]="orderDetail.id && paymentMethod.abbreviation == 'cc'"
                                                        (click)="orderDetail.paymentMethodId = paymentMethod.id">
                                                    {{paymentMethod.displayName}}
                                                </button>
                                                <div *ngIf="isPaymentMethodCreditCard">
                                                    <span><strong>{{creditCardText}}</strong></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 unset-height" *ngIf="isPaymentMethodCheck">
                                            <div class="mb-5 non-label-spacer-div">
                                                <strong>
                                                    {{l("ChecksPayableName")}}: <span style="background-color: yellow">{{checksPayableName}}</span>
                                                </strong>
                                            </div>
                                            <div class="mb-5 non-label-spacer-div">
                                                <label>{{l("CheckNumber")}}</label>
                                                <input type="text" class="form-control" [(ngModel)]="orderDetail.checkNumber"
                                                       (onInput)="validateCheckNumber()" (onBlur)="validateCheckNumber()"
                                                       name="{{l('CheckNumber')}}" minlength="0" maxlength="10" data-lpignore="true" />
                                            </div>
                                            <span *ngIf="checkNumberValidationMessage" class="validation-error">{{checkNumberValidationMessage}}</span>
                                        </div>

                                        <div class="col-12 unset-height" *ngIf="!orderDetail.id && isPaymentMethodCreditCard && !creditCardText">
                                            <div class="mb-5 non-label-spacer-div">
                                                <div class="label-height hide-on-mobile">
                                                    <div *ngIf="customerCCOrderEmailAddress">{{l("CustomerEmailAddress")}}</div>
                                                </div>
                                                <input #addressEmail="ngModel" type="text" class="form-control form-control-format"
                                                       [(ngModel)]="customerCCOrderEmailAddress" name="{{l('CustomerEmailAddress')}}" placeholder="{{l('CustomerEmailAddress')}}"
                                                       [pattern]="emailAddressPattern" minlength="0"
                                                       maxlength="128" required data-lpignore="true" />
                                                <validation-messages [errorDefs]="[{ pattern: l('InvalidEmailAddress') }]"
                                                                     [formCtrl]="addressEmail"></validation-messages>
                                            </div>
                                            <div class="mb-5 non-label-spacer-div">
                                                <button class="btn btn-primary payment-button" type="button" (click)="sendSharedOrder()"
                                                        [disabled]="!customerCCOrderEmailAddress || addressEmail.invalid">
                                                    {{l("SendOrder")}}
                                                </button>
                                            </div>
                                            <div class="alert alert-warning non-label-spacer-div">
                                                {{l("YouMustBeLoggedOut")}}
                                            </div>
                                        </div>

                                        <ng-container *ngIf="!studentCreditCardOnly">
                                            <div class="mb-3 d-flex flex-center unset-height">
                                                <div class="row student-buttons-row">
                                                    <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                            [ngClass]="(orderDetail.paid && paidIsSet)?'btn-selected':'btn-default'"
                                                            type="button" (click)="orderDetail.paid = true; paidIsSet = true;">
                                                        {{l("Paid")}}
                                                    </button>
                                                    <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                            [ngClass]="(!orderDetail.paid && paidIsSet)?'btn-selected':'btn-default'"
                                                            type="button" (click)="orderDetail.paid = false; paidIsSet = true;">
                                                        {{l("NotPaid")}}
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <div class="mb-3 d-flex flex-center unset-height" *ngIf="!studentCreditCardOnly && isAProductSelected">
                                            <div class="row student-buttons-row">
                                                <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="(orderDetail.delivered)?'btn-selected':'btn-default'" type="button"
                                                        (click)="orderDetail.delivered = true">
                                                    {{l("Delivered")}}
                                                </button>
                                                <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="(!orderDetail.delivered)?'btn-selected':'btn-default'" type="button"
                                                        (click)="orderDetail.delivered = false">
                                                    {{l("NotDelivered")}}
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngIf="orderDetail.id && isPaymentMethodCreditCard && orderDetail.invoice">
                                    <div class="row">
                                        <div class="col">
                                            {{l("CreditCardSales")}} {{l("Invoice")}}: {{orderDetail.invoice}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-center bottom-buttons-container">
                            <button class="customer-button customer-submit-button enabled-background"
                                    type="submit" [buttonBusy]="isProcessingOrder" [busyText]="l('SavingWithThreeDot')">
                                {{ orderDetail.id ? l("UpdateOrder") : l("Donate") }}
                            </button>
                        </div>
                        <div class="d-flex align-items-center justify-content-center bottom-buttons-container mt-3">
                            <a *ngIf="!orderDetailId" class="go-back-link" (click)="continueShopping()">
                                {{ l("GoBack") }}
                            </a>
                        </div>
                        <div class="d-flex align-items-center justify-content-center mt-3">
                            <p style="max-width: 600px;" class="text-center">
                                {{l("DonationBillingAuthorizationText").replace('There may be a handling fee. ', '')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="live-link-footer">
                <div class="d-flex flex-row justify-content-center w-100">
                    <div class="footer-organization-logo-container my-auto">
                        <img src="{{tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
                    </div>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Contact Us</a>
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Run your own Fundraiser</a>
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Join our team</a>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a (click)="openInNewTab(appUrlService.appRootUrl + 'TermsOfUse.html')">Terms of Use</a>
                </div>
                <div class="d-flex flex-row justify-content-center w-100">
                    <div class="yellow-text d-flex align-items-end">
                        <div class="d-flex flex-column">
                            <div class="fundraiser-name-and-powered-by-container">
                                <div class="powered-by-container">
                                    <div class="powered-by-text">{{l('PoweredBy')}}</div>
                                    <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </form>
</div>