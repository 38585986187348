<div [@routerTransition] class="background">
    <form #orderForm="ngForm" novalidate autocomplete="off" (ngSubmit)="save()" class="w-100">
        <div class="h-100 w-100" style="background-color: white;">
            <div class="d-flex align-items-center justify-content-center w-100 header-container">
                <div class="row top-row">
                    <div class="col-lg-12">
                        <div class="logo-fundraiser-name row">
                            <div class="hour-a-thon-org-logo-container col-4 col-sm-3 col-md-2 my-auto">
                                <img src="{{logoUrl}}" alt="logo" class="hour-a-thon-logo">
                            </div>
                            <img src="/assets/common/images/circle-mask-3.svg" alt="logo" class="hour-a-thon-logo logo-mask" />
                            <div class="col-8 col-sm-9 col-md-8  hour-a-thon-fundraiser-name yellow-text">
                                <div class="d-flex flex-column">
                                    <div class="hour-a-thon-organization-name brush-stroke">{{organizationName}}</div>
                                    <div class="fundraiser-name-and-powered-by-container">
                                        <span class="fundraiser-name">{{fundraiserName}}</span>
                                        <div class="powered-by-container">
                                            <img alt="Fundraiser Group Logo" src="{{tenantLogoUrl}}" class="powered-by-logo" />
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="teamMemberNameInput">
                                    <span class="powered-by-text">{{l('OnBehalfOf')}} </span><span class="brush-stroke yellow-text student-progress-footer-name">{{teamMemberNameInput}}</span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-40 overlay d-flex justify-content-center" style="background-color: white;">
                <div class="row middle-row">

                    <!-- saving/processing order -->
                    <div *ngIf="isProcessingOrder">
                        <div class="text-center p-5">
                            <h2 class="heading-white">{{l('PleaseWait')}}</h2>
                            <img class="busy-dots" alt="Order is being processed" src="/assets/common/images/busy-dots.gif" />
                            <p class="processing-text">{{l('OrderIsBeingProcessed')}}</p>
                            <p class="processing-text">{{l('DontRefresh')}}</p>
                            <div id="processing-order-spinner"></div>
                        </div>
                    </div>

                    <div *ngIf="!isProcessingOrder && orderDetail" class="h-100 w-100 d-flex flex-column align-items-center justify-content-center form-parent">
                        <div class="headline">{{l('CheckOut')}}</div>
                        <div class="row w-100 d-flex flex-column align-items-center ">

                            <div class="col-12 col-lg-8 d-flex justify-content-center summary-column">
                                <div class="summary-column-inner">
                                    <div class="order-info-header">{{l("OrderInfo")}}</div>

                                    <div style="border-bottom: 1px solid lightgray" class="pb-2 mb-3">
                                        <div *ngFor="let productOrder of selectedProductOrders; let i = index" [attr.data-index]="i" [style.background-color]="i % 2 == 0 ? 'white' : '#ebf8fa'">
                                            <div class="row product-summary-row" *ngIf="productOrder.quantity >= 1">
                                                <div class="col-1 m--align-right align-self-center">
                                                    <i class="remove-product-icon fa fa-times product-remove" title="Remove product"
                                                       (click)="removeProduct(productOrder)"></i>
                                                </div>
                                                <div class="col-5 col-sm-6 m--align-left item-description">
                                                    {{productOrder.productName}}
                                                    <span *ngIf="productOrder.productOptionId"
                                                          style="word-break:break-word;">{{productOrder.productOptionName}}</span>
                                                </div>
                                                <div class="col-4 col-sm-3 text-center align-self-center">
                                                    <div class="product-increment-decrement">
                                                        <i class="product-quantity-decrement fa fa-minus-circle"
                                                           (click)="decrementQuantity(productOrder, false)"></i>
                                                        <div class="item-quantity">{{productOrder.quantity}}</div>
                                                        <i class="product-quantity-increment fa fa-plus-circle"
                                                           (click)="incrementQuantity(productOrder)"></i>
                                                    </div>
                                                </div>
                                                <div class="col-2 col-sm-2 m--align-right align-self-center item-total p-0">
                                                    <div class="order-summary-item-right-side">
                                                        {{ productOrder.quantity * productOrder.price | currency: 'USD' : 'symbol' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="orderTotalsUpdateResult?.discountTotal && orderTotalsUpdateResult.discountTotal > 0"
                                         class="d-flex justify-content-between w-100">
                                        <span class="order-info-text-color">
                                            {{l("QuantityDiscountLabel")}}
                                        </span>
                                        <div class="order-summary-right-side">
                                            -{{orderTotalsUpdateResult?.discountTotal | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div *ngIf="orderDetail.directShip" class="d-flex justify-content-between w-100">
                                        <span class="order-info-text-color">
                                            {{l("ShippingFeeLabel")}}
                                        </span>
                                        <div class="order-summary-right-side">
                                            {{orderTotalsUpdateResult?.shippingFee | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div *ngIf="!isDonationOnly && acceptDonations" class="d-flex justify-content-between w-100">
                                        <span class="order-info-text-color">
                                            {{l("Donation")}}:
                                        </span>
                                        <div class="order-summary-right-side">
                                            {{orderTotalsUpdateResult?.donationTotal | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div *ngIf="guestPayMode == PAY_CC && orderTotalsUpdateResult?.convenienceFee > 0" class="d-flex justify-content-between w-100">
                                        <span>
                                            {{l("ConvenienceFeeLabel", convenienceFee)}}
                                        </span>
                                        <div class="order-summary-right-side">
                                            {{orderTotalsUpdateResult?.convenienceFee | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-between w-100 order-info-total">
                                        <span>
                                            {{l("Total")}}
                                        </span>
                                        <div class="order-summary-right-side">
                                            {{orderTotalsUpdateResult?.orderTotal + tipDollars | currency:'USD':'symbol':'1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 d-flex">
                            <div class="row inputs-column">
                                <!-- DIRECT SHIP OPTION: User needs to choose an option -->
                                <div *ngIf="isGuest && directShipAvailable && orderTotalsUpdateResult?.totalItems > 0" class="top-margin-md">
                                    <h4>{{l("DirectShipHeader")}}</h4>
                                    <div class="m--padding-10">
                                        {{l("DirectShipMessage")}}
                                    </div>
                                    <div class="row paid-status-buttons-main mt-5">
                                        <button class="btn payment-button payment-button" style="border: 1px solid #707070 !important;"
                                                [ngClass]="orderDetail.directShip?'btn-selected':'btn-default'" type="button"
                                                (click)="updateShipOption(true)">
                                            {{l("DirectShipToMe")}}
                                        </button>
                                        <button class="btn payment-button payment-button" style="border: 1px solid #707070 !important;"
                                                [ngClass]="!orderDetail.directShip?'btn-selected':'btn-default'" type="button"
                                                (click)="updateShipOption(false)">
                                            {{l("HandDeliverToMe")}}
                                        </button>
                                    </div>
                                </div>

                                <!-- DIRECT SHIP OPTION: Direct ship is the only option -->
                                <div *ngIf="isGuest && forceDirectShip && orderTotalsUpdateResult?.totalItems > 0" class="top-margin-md">
                                    <h4>{{l("DirectShipHeader")}}</h4>
                                    <div class="m--padding-10">
                                        {{l("ForceDirectShipMessage")}}
                                    </div>
                                </div>

                                <ng-container *ngIf="!teamMemberNameInput">
                                    <div class="col-12 col-md-6">
                                        <div>
                                            <div *ngIf="teamMemberId">{{l("OnBehalfOf")}}</div>
                                        </div>
                                        <select #teamMemberSelect="ngModel" name="{{l('OnBehalfOf')}}"
                                                class="form-control contact-field-short"
                                                [(ngModel)]="teamMemberId" required>
                                            <option [ngValue]="undefined" disabled>{{l("OnBehalfOf")}}</option>
                                            <option [value]="-1">Other</option>
                                            <option *ngFor="let teamMember of availableSellers" [value]="teamMember.id">{{teamMember.name}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="teamMemberSelect"></validation-messages>
                                    </div>
                                    <div class="col-6 custom-seller-placeholder"></div>

                                    <div class="col-12 col-md-6" *ngIf="teamMemberId==-1">
                                        <div>
                                            <div *ngIf="teamMemberFirstName">{{l("TeamMemberFirstName")}}</div>
                                        </div>
                                        <input #teamMemberFirstNameInput="ngModel" name="{{l('TeamMemberFirstName')}}"
                                               type="text" [(ngModel)]="teamMemberFirstName" placeholder="{{l('TeamMemberFirstName')}}"
                                               required min="1" max="100" data-lpignore="true" />
                                        <validation-messages [formCtrl]="teamMemberFirstNameInput"></validation-messages>
                                    </div>
                                    <div class="col-12 col-md-6" *ngIf="teamMemberId==-1">
                                        <div>
                                            <div *ngIf="teamMemberLastName">{{l("TeamMemberLastName")}}</div>
                                        </div>
                                        <input #teamMemberLastNameInput="ngModel" name="{{l('TeamMemberLastName')}}"
                                               type="text" [(ngModel)]="teamMemberLastName" placeholder="{{l('TeamMemberLastName')}}"
                                               required min="1" max="100" data-lpignore="true" />
                                        <validation-messages [formCtrl]="teamMemberLastNameInput"></validation-messages>
                                    </div>
                                </ng-container>

                                <div class="col-12 col-md-6">
                                    <div>
                                        <div *ngIf="firstName">{{l('FirstName')}}</div>
                                    </div>
                                    <input #addressBillingFirstNameInput="ngModel" name="{{l('FirstName')}}"
                                           type="text" [(ngModel)]="firstName" placeholder="{{l('FirstName')}}"
                                           required minlength="1" maxlength="128" data-lpignore="true" />
                                    <validation-messages [formCtrl]="addressBillingFirstNameInput"></validation-messages>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div>
                                        <div *ngIf="lastName">{{l('LastName')}}</div>
                                    </div>
                                    <input #addressBillingLastNameInput="ngModel" name="{{l('LastName')}}"
                                           type="text" [(ngModel)]="lastName" class="col-6" placeholder="{{l('LastName')}}"
                                           required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" data-lpignore="true" />
                                    <validation-messages [formCtrl]="addressBillingLastNameInput"></validation-messages>
                                </div>

                                <label class="col-12 d-flex align-items-center checkbox-label">
                                    <input name="isAnonymousCheckbox" type="checkbox" [(ngModel)]="orderDetail.isCustomerAnonymous" />
                                    {{l('MakeMeAnonymous')}}
                                </label>

                                <div *ngIf="acceptDonations" class="col-6">
                                    <div>
                                        <div *ngIf="donation != null">{{l("Donation")}}</div>
                                    </div>
                                    <ng-container *ngIf="isDonationOnly">
                                        <p-inputNumber #donationAmountInput="ngModel" name="{{l('Donation')}}" inputId="donationAmountInput" id="donationAmountInput"
                                                       [(ngModel)]="donation" mode="currency" currency="USD" locale="en-US"
                                                       placeholder="{{l('Donation')}}" (focusout)="updateTotals()"
                                                       ngDefaultControl class="form-control">
                                        </p-inputNumber>
                                        <span *ngIf="!isDonationValid" class="validation-error">{{l('DonationMustBeAtLeastTenDollars')}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="!isDonationOnly">
                                        <p-inputNumber #donationAmountInput="ngModel" name="{{l('Donation')}}" inputId="donationAmountInput" id="donationAmountInput"
                                                       [(ngModel)]="donation" mode="currency" currency="USD" locale="en-US"
                                                       placeholder="{{l('Donation')}}" (focusout)="updateTotals()"
                                                       ngDefaultControl class="form-control">
                                        </p-inputNumber>
                                        <span *ngIf="!isDonationValid" class="validation-error">{{l('DonationMustBeAtLeastTenDollars')}}</span>
                                    </ng-container>
                                </div>

                                <ng-container *ngIf="isDonationOnly && !orderDetail.id">
                                    <div class="col-6 col-md-3">
                                        <div>
                                            <div class="d-inline-block">{{l("Tip")}}&nbsp;</div>
                                            <div (click)="showTipInfo()" class="d-inline-block order-cart-help-circle"
                                                 matTooltip="Info about the action">
                                                <i class="fa fa-question-circle"></i>
                                            </div>
                                        </div>
                                        <select name="{{l('Tip')}}"
                                                class="form-control contact-field-short"
                                                [(ngModel)]="tipSelection" required>
                                            <option *ngFor="let tipOption of tipOptions" [value]="tipOption">{{tipOption}}</option>
                                        </select>
                                    </div>
                                    <ng-container *ngIf="tipSelection == 'Other'">
                                        <div class="show-on-mobile col-6">
                                        </div>
                                        <div class="col-6 col-md-3">
                                            <div>
                                                <div class="d-inline-block">{{l("CustomTip")}}</div>
                                            </div>
                                            <input type="number" min="0" max="100" name="{{l('CustomTip')}}" [(ngModel)]="customTipPercent" />
                                            <div *ngIf="!isCustomTipValid" class="validation-error">{{l("TipValueIsInvalid")}}</div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="orderDetail.id">
                                    <div class="col-6">
                                        <div>
                                            <div class="d-inline-block">{{l("Tip")}}</div>
                                        </div>
                                        <p-inputNumber #donationAmountInput="ngModel" name="{{l('Tip')}}" inputId="tipDollarsInput" id="tipDollarsInput"
                                                       [(ngModel)]="editOrderTipDollars" mode="currency" currency="USD" locale="en-US"
                                                       placeholder="{{l('Tip')}}" (focusout)="updateTotals()"
                                                       ngDefaultControl class="form-control">
                                        </p-inputNumber>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!showPaymentOptions && !orderDetail.id">
                                    <div class="col-12 col-md-7">
                                        <div>
                                            <div *ngIf="orderDetail.creditCardInfo.creditCardNumber">{{l('DebitOrCreditCard')}}</div>
                                        </div>
                                        <input #creditCardNumberInput="ngModel" name="{{l('DebitOrCreditCard')}}"
                                               type="text" [(ngModel)]="orderDetail.creditCardInfo.creditCardNumber" placeholder="{{l('DebitOrCreditCard')}}"
                                               required minlength="13" maxlength="20" data-lpignore="true" />
                                        <validation-messages [formCtrl]="creditCardNumberInput"></validation-messages>
                                    </div>

                                    <div class="col-6 col-md-3">
                                        <div>
                                            <div *ngIf="expires">{{l("ExpDate")}}</div>
                                        </div>

                                        <p-inputMask #expiresInput="ngModel" name="{{l('ExpDate')}}" [autoClear]="false"
                                                     mask="99/99" [(ngModel)]="expires" placeholder="{{l('ExpDate')}}" ngDefaultControl
                                                     (onInput)="validateExpiresDate()" (onBlur)="validateExpiresDate()"
                                                     class="form-control contact-field"
                                                     required data-lpignore="true">
                                        </p-inputMask>
                                        <span *ngIf="expiresErrorMessage" class="validation-error">{{expiresErrorMessage}}</span>
                                    </div>

                                    <div class="col-6 col-md-2">
                                        <div>
                                            <div *ngIf="orderDetail.creditCardInfo.cvv" class="d-inline-block">
                                                {{l("CVVShort")}}&nbsp;
                                            </div>
                                            <a (click)="openInNewTab('https://www.cvvnumber.com/cvv.html')" 
                                               class="order-cart-help-circle" rel="noopener noreferrer">
                                                <i class="fa fa-question-circle"></i>
                                            </a>
                                        </div>
                                        <input #cvvInput="ngModel" name="{{l('CVVShort')}}"
                                               type="text" [(ngModel)]="orderDetail.creditCardInfo.cvv" placeholder="{{l('CVVShort')}}"
                                               required minlength="3" maxlength="4" pattern="^[0-9]+$" data-lpignore="true" />
                                        <span *ngIf="!cvvInput.valid && cvvInput.touched"
                                              class="validation-error">{{l("CVVInvalid")}}</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="orderDetail.customerAddress">
                                    <div class="col-9">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.streetLine1">{{l("BillingAddress")}}</div>
                                        </div>
                                        <input #streetInput="ngModel" name="{{l('BillingAddress')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.streetLine1" placeholder="{{l('BillingAddress')}}"
                                               required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" data-lpignore="true" />
                                        <validation-messages [formCtrl]="streetInput"></validation-messages>
                                    </div>
                                    <div class="col-3">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.streetLine2">{{l("AddressTwo")}}</div>
                                        </div>
                                        <input #streetInput2="ngModel" name="{{l('AddressTwo')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.streetLine2" placeholder="{{l('AddressTwo')}}"
                                               maxlength="32" data-lpignore="true" />
                                        <validation-messages [formCtrl]="streetInput2"></validation-messages>
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.city">{{l("City")}}</div>
                                        </div>
                                        <input #cityInput="ngModel" name="{{l('City')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.city" placeholder="{{l('City')}}"
                                               required="{{isGuest && isPaymentMethodCreditCard}}" minlength="1" maxlength="128" />
                                        <validation-messages [formCtrl]="cityInput"></validation-messages>
                                    </div>

                                    <div class="col-7 col-md-4">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.stateId">{{l("State")}}</div>
                                        </div>
                                        <select #stateSelect="ngModel" name="{{l('State')}}"
                                                class="form-control contact-field-short"
                                                [(ngModel)]="orderDetail.customerAddress.stateId" required="{{isGuest && isPaymentMethodCreditCard}}">
                                            <option [ngValue]="undefined" disabled>State</option>
                                            <option *ngFor="let state of states" [value]="state.id">{{state.displayName}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="stateSelect"></validation-messages>
                                    </div>

                                    <div class="col-5 col-md-2">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.zipCode">{{l("ZipCode")}}</div>
                                        </div>
                                        <input #zipInput="ngModel" name="{{l('ZipCode')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.zipCode" placeholder="{{l('ZipCode')}}"
                                               minlength="5" maxlength="10" required="{{isGuest && isPaymentMethodCreditCard}}" data-lpignore="true" />
                                        <validation-messages [formCtrl]="zipInput"></validation-messages>
                                    </div>

                                    <div class="col-12 col-md-5">
                                        <div>
                                            <div>{{l("Phone")}}</div>
                                        </div>
                                        <p-inputMask #phoneInput="ngModel" name="{{l('Phone')}}" [autoClear]="false"
                                                     mask="(999) 999-9999" [(ngModel)]="orderDetail.customerAddress.phoneNumber"
                                                     placeholder="(___) ___-____" ngDefaultControl (onInput)="validatePhoneNumber()" (onBlur)="validatePhoneNumber()"
                                                     class="form-control" data-lpignore="true">
                                        </p-inputMask>
                                        <span *ngIf="phoneErrorMessage" class="validation-error">{{phoneErrorMessage}}</span>
                                    </div>

                                    <div class="col-12 col-md-7">
                                        <div>
                                            <div *ngIf="orderDetail.customerAddress.emailAddress">{{l("Email")}}</div>
                                        </div>
                                        <input #emailInput="ngModel" name="{{l('Email')}}"
                                               type="text" [(ngModel)]="orderDetail.customerAddress.emailAddress" placeholder="{{l('Email')}}"
                                               [required]="(isGuest && isPaymentMethodCreditCard) || orderContainsDGCSelection" maxlength="256" [pattern]="emailAddressPattern" />
                                        <validation-messages [errorDefs]="[{ pattern: l('InvalidEmailAddress') }]"
                                                             [formCtrl]="emailInput"></validation-messages>
                                    </div>
                                </ng-container>

                                <!-- shipping address -->
                                <ng-container *ngIf="orderDetail.directShip">
                                    <div class="my-3 pt-5">
                                        <h4>{{l("AddressSendTo")}}</h4>
                                        <div class="mb-5">
                                            <div class="row">
                                                <button class="btn payment-button payment-button mb-3" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="!setShippingAddress?'btn-selected':'btn-default'" type="button"
                                                        (click)="setShippingAddress = false;">
                                                    {{l("AddressSameAsBilling")}}
                                                </button>
                                                <button class="btn payment-button payment-button mb-3" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="setShippingAddress?'btn-selected':'btn-default'" type="button"
                                                        (click)="setShippingAddress = true;">
                                                    {{l("AddressSetShipping")}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="setShippingAddress">
                                        <div class="col-12 col-md-6">
                                            <div>
                                                <div *ngIf="orderDetail.shippingAddress.name">{{l('Name')}}</div>
                                            </div>
                                            <input #shippingName="ngModel" name="{{l('Name')}}"
                                                   type="text" [(ngModel)]="orderDetail.shippingAddress.name" placeholder="{{l('Name')}}"
                                                   required minlength="1" maxlength="128" data-lpignore="true" />
                                            <validation-messages [formCtrl]="shippingName"></validation-messages>
                                        </div>

                                        <div class="col-9">
                                            <div>
                                                <div *ngIf="orderDetail.shippingAddress.streetLine1">{{l("ShippingAddress")}}</div>
                                            </div>
                                            <input #shippingStreet="ngModel" name="{{l('ShippingAddress')}}"
                                                   type="text" [(ngModel)]="orderDetail.shippingAddress.streetLine1" placeholder="{{l('ShippingAddress')}}"
                                                   required minlength="1" maxlength="128" data-lpignore="true" />
                                            <validation-messages [formCtrl]="shippingStreet"></validation-messages>
                                        </div>
                                        <div class="col-3">
                                            <div>
                                                <div *ngIf="orderDetail.shippingAddress.streetLine2">{{l("AddressTwo")}}</div>
                                            </div>
                                            <input #shippingStreet2="ngModel" name="{{l('AddressTwo')}}"
                                                   type="text" [(ngModel)]="orderDetail.shippingAddress.streetLine2" placeholder="{{l('AddressTwo')}}"
                                                   maxlength="32" data-lpignore="true" />
                                            <validation-messages [formCtrl]="shippingStreet2"></validation-messages>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <div>
                                                <div *ngIf="orderDetail.shippingAddress.city">{{l("City")}}</div>
                                            </div>
                                            <input #shippingCity="ngModel" name="Ship-to {{l('City')}}"
                                                   type="text" [(ngModel)]="orderDetail.shippingAddress.city" placeholder="{{l('City')}}"
                                                   required minlength="1" maxlength="128" />
                                            <validation-messages [formCtrl]="shippingCity"></validation-messages>
                                        </div>

                                        <div class="col-7 col-md-4">
                                            <div>
                                                <div *ngIf="orderDetail.shippingAddress.stateId">{{l("State")}}</div>
                                            </div>
                                            <select #shippingState="ngModel" name="Ship-to {{l('State')}}"
                                                    class="form-control contact-field-short"
                                                    [(ngModel)]="orderDetail.shippingAddress.stateId" required>
                                                <option [ngValue]="undefined" disabled>State</option>
                                                <option *ngFor="let state of states" [value]="state.id">{{state.displayName}}</option>
                                            </select>
                                            <validation-messages [formCtrl]="shippingState"></validation-messages>
                                        </div>

                                        <div class="col-5 col-md-2">
                                            <div>
                                                <div *ngIf="orderDetail.shippingAddress.zipCode">{{l("ZipCode")}}</div>
                                            </div>
                                            <input #shippingZip="ngModel" name="Ship-to {{l('ZipCode')}}"
                                                   type="text" [(ngModel)]="orderDetail.shippingAddress.zipCode" placeholder="{{l('ZipCode')}}"
                                                   minlength="5" maxlength="10" required data-lpignore="true" />
                                            <validation-messages [formCtrl]="shippingZip"></validation-messages>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <div *ngIf="showPaymentOptions || orderDetail.id" class="mt-5">
                                    <!-- in-person sales can select payment method -->
                                    <ng-container *ngIf="!(isPaymentMethodCreditCard && orderDetail.invoice)">
                                        <div class="mb-3 d-flex flex-center unset-height">
                                            <div class="row student-buttons-row">
                                                <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="(orderDetail.paymentMethodId == paymentMethod.id)?'btn-selected':'btn-default'"
                                                        type="button" *ngFor="let paymentMethod of paymentMethods"
                                                        [hidden]="orderDetail.id && paymentMethod.abbreviation == 'cc'"
                                                        (click)="orderDetail.paymentMethodId = paymentMethod.id">
                                                    {{paymentMethod.displayName}}
                                                </button>
                                                <div *ngIf="isPaymentMethodCreditCard">
                                                    <span><strong>{{creditCardText}}</strong></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 unset-height" *ngIf="isPaymentMethodCheck">
                                            <div class="mb-5 non-label-spacer-div">
                                                <strong>
                                                    {{l("ChecksPayableName")}}: <span style="background-color: yellow">{{checksPayableName}}</span>
                                                </strong>
                                            </div>
                                            <div class="mb-5 non-label-spacer-div">
                                                <label>{{l("CheckNumber")}}</label>
                                                <input type="text" class="form-control" [(ngModel)]="orderDetail.checkNumber"
                                                       (onInput)="validateCheckNumber()" (onBlur)="validateCheckNumber()"
                                                       name="{{l('CheckNumber')}}" minlength="0" maxlength="10" data-lpignore="true" />
                                            </div>
                                            <span *ngIf="checkNumberValidationMessage" class="validation-error">{{checkNumberValidationMessage}}</span>
                                        </div>

                                        <div class="col-12 unset-height"
                                             *ngIf="isPaymentMethodCreditCard && !creditCardText"
                                             [hidden]="orderDetail.id">
                                            <div class="mb-5 non-label-spacer-div">
                                                <div class="label-height">
                                                    <div *ngIf="customerCCOrderEmailAddress">{{l("CustomerEmailAddress")}}</div>
                                                </div>
                                                <input #addressEmail="ngModel" type="text" class="form-control form-control-format"
                                                       [(ngModel)]="customerCCOrderEmailAddress" name="{{l('CustomerEmailAddress')}}" placeholder="{{l('CustomerEmailAddress')}}"
                                                       [pattern]="emailAddressPattern" minlength="0"
                                                       maxlength="128" required data-lpignore="true" />
                                                <validation-messages [errorDefs]="[{ pattern: l('InvalidEmailAddress') }]"
                                                                     [formCtrl]="addressEmail"></validation-messages>
                                            </div>
                                            <div class="mb-5 non-label-spacer-div">
                                                <button class="btn btn-primary payment-button" type="button" (click)="sendSharedOrder()"
                                                        [disabled]="!customerCCOrderEmailAddress || addressEmail.invalid">
                                                    {{l("SendOrder")}}
                                                </button>
                                            </div>
                                            <div class="alert alert-warning non-label-spacer-div">
                                                {{l("YouMustBeLoggedOut")}}
                                            </div>
                                        </div>

                                        <ng-container *ngIf="!studentCreditCardOnly">
                                            <div class="mb-3 d-flex flex-center unset-height">
                                                <div class="row student-buttons-row">
                                                    <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                            [ngClass]="(orderDetail.paid && paidIsSet)?'btn-selected':'btn-default'"
                                                            type="button" (click)="orderDetail.paid = true; paidIsSet = true;">
                                                        {{l("Paid")}}
                                                    </button>
                                                    <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                            [ngClass]="(!orderDetail.paid && paidIsSet)?'btn-selected':'btn-default'"
                                                            type="button" (click)="orderDetail.paid = false; paidIsSet = true;">
                                                        {{l("NotPaid")}}
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <div class="mb-3 d-flex flex-center unset-height" *ngIf="!studentCreditCardOnly && isAProductSelected">
                                            <div class="row student-buttons-row">
                                                <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="(orderDetail.delivered)?'btn-selected':'btn-default'" type="button"
                                                        (click)="orderDetail.delivered = true">
                                                    {{l("Delivered")}}
                                                </button>
                                                <button class="btn payment-button" style="border: 1px solid #707070 !important;"
                                                        [ngClass]="(!orderDetail.delivered)?'btn-selected':'btn-default'" type="button"
                                                        (click)="orderDetail.delivered = false">
                                                    {{l("NotDelivered")}}
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="orderDetail.id && isPaymentMethodCreditCard && orderDetail.invoice">
                                    <div class="row">
                                        <div class="col">
                                            {{l("CreditCardSales")}} {{l("Invoice")}}: {{orderDetail.invoice}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!isDonationOnly" class="mb-5 text-center repinfo">
                            <br />
                            <div *ngIf="!isGuest">
                                <div pInplaceDisplay *ngIf="repInfo != undefined">
                                    <h3 class="repInfo">
                                        Rep Contact Info: <span *ngIf="repInfo.fullName">{{repInfo.fullName}}</span><span *ngIf="repInfo.emailAddress"> | {{repInfo.emailAddress}}</span><span *ngIf="repInfo.phoneNumber"> | {{repInfo.phoneNumber}}</span>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-center bottom-buttons-container">
                            <button *ngIf="!(orderDetailId && isLaunchathon)" type="button" class="customer-button customer-cancel-button" (click)="continueShopping()">
                                {{ l("Back") }}
                            </button>
                            <button class="customer-button customer-submit-button enabled-background"
                                    type="submit" [buttonBusy]="isProcessingOrder" [busyText]="l('SavingWithThreeDot')">
                                {{ orderDetail.id ? l("UpdateOrder") : (isLaunchathon ? l("Donate") : l("PayNow")) }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="live-link-footer">
                <div class="d-flex flex-row justify-content-center w-100">
                    <div class="footer-organization-logo-container my-auto">
                        <img src="{{tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
                    </div>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Contact Us</a>
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Run your own Fundraiser</a>
                    <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Join our team</a>
                </div>
                <div class="footer-links d-flex flex-wrap justify-content-center">
                    <a (click)="openInNewTab(appUrlService.appRootUrl + 'TermsOfUse.html')">Terms of Use</a>
                </div>
                <div class="d-flex flex-row justify-content-center w-100">
                    <div class="yellow-text d-flex align-items-end">
                        <div class="d-flex flex-column">
                            <div class="fundraiser-name-and-powered-by-container">
                                <div class="powered-by-container">
                                    <div class="powered-by-text">{{l('PoweredBy')}}</div>
                                    <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </form>
</div>