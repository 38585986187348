<div [@routerTransition] class="background row g-0">
    <div class="overlay g-0" [hidden]="!fundraiserForLiveLink">
        <div class="container main-container h-100 col">
            <div class="row mb-40">
                <div class="col-lg-9">
                    <div class="logo-fundraiser-name row mb-3">
                        <div class="col-3 hour-a-thon-org-logo-container my-auto">
                            <img #logoImage src="{{logoUrl}}" alt="logo" class="hour-a-thon-logo">
                        </div>
                        <img src="/assets/common/images/circle-mask-3.svg" alt="logo" class="hour-a-thon-logo logo-mask" />
                        <div class="col-9 hour-a-thon-fundraiser-name">
                            <div class="d-flex flex-column">
                                <div class="hour-a-thon-organization-name">{{organizationName}}</div>
                                <div class="fundraiser-name-and-powered-by-container">
                                    <span class="fundraiser-name">{{fundraiserName}}</span>
                                    <div class="powered-by-container">
                                        <img alt="Fundraiser Group Logo" src="{{tenantLogoUrl}}" class="powered-by-logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <live-link-countdown [fundraiserForLiveLink]="fundraiserForLiveLink"></live-link-countdown>
                </div>
            </div>
            <div class="row g-0">
                <div class="col-12 col-xl-9 mb-5">
                    <div class="hour-a-thon-col">
                        <live-link-totals [fundraiserForLiveLink]="fundraiserForLiveLink"></live-link-totals>
                    </div>
                </div>
                <div class="col-12 col-xl-3">
                    <div class="hour-a-thon-col">
                        <live-link-activity [fundraiserForLiveLink]="fundraiserForLiveLink"></live-link-activity>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <footer class="live-link-footer row g-0">
        <div class="d-flex flex-row justify-content-center w-100">
            <div class="footer-organization-logo-container my-auto">
                <img src="{{tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
            </div>
        </div>
        <div class="footer-links d-flex flex-wrap justify-content-center">
            <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Contact Us</a>
            <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Run your own Fundraiser</a>
            <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Join our team</a>
        </div>
        <div class="footer-links d-flex flex-wrap justify-content-center">
            <a (click)="openInNewTab(appUrlService.appRootUrl + 'TermsOfUse.html')">Terms of Use</a>
        </div>
        <div class="d-flex flex-row justify-content-center w-100">
            <div class="yellow-text d-flex align-items-end">
                <div class="d-flex flex-column">
                    <div class="fundraiser-name-and-powered-by-container">
                        <div class="powered-by-container">
                            <div class="powered-by-text">{{l('PoweredBy')}}</div>
                            <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div *ngIf="!fundraiserName">
        {{l('FundraiserNotFound')}}
    </div>
</div>