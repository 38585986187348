<div class="row inputs-column mb-2 mt-3 px-5" *ngIf="customerInformation">
    <span class="border launch-border-primary border-1 launch-br">
        <div class="row d-flex align-items-center mb-5 px-3">
            <div class="col-6 d-flex justify-content-start">
                <img src="assets/common/images/iSponsor-US-Light-1.png" alt="iSponsor Logo" class="w-100 mw-300px">
            </div>
            <div class="col-6 d-flex justify-content-end">
                <img src="assets/common/images/LaunchBack All Logos Final-02.jpg" alt="Launchback Logo" class="w-100 mw-300px">
            </div>
        </div>
        <div class="row d-flex align-items-center justify-content-center" *ngIf="!showConfirmationMessage; else confirmationMessage">
            <h3 class="text-center mb-2">{{l('ISponsorLaunchPartnership')}}</h3>
            <h3 class="fw-normal text-center">{{l('ISponsorLaunchPromotionalText')}}</h3>
            <div class="w-100 d-flex justify-content-center my-2">
                <button type="button" class="btn btn-primary btn-large" (click)="optInToISponsor()">{{l('ISponsorLaunchOptInButton')}}</button>
            </div>
            <div class="w-100 d-flex justify-content-center mb-3">
                <button type="button" class="btn btn-outline btn-sm" (click)="close()">{{l('ISponsorLaunchCloseButton')}}</button>
            </div>
            <small class="fw-normal text-center mb-5">
                {{l('ISponsorLaunchDisclaimer')}}
                <a class="fw-bold" (click)="openInNewTab('https://isponsor.co/customer-tcs/')">{{l('TermsAndConditions')}}</a>
                and
                <a class="fw-bold" (click)="openInNewTab('https://isponsor.co/privacy-policy')">{{l('PrivacyPolicy')}}.</a>
                {{l('ISponsorLaunchDisclaimer2')}}
            </small>
        </div>
        <ng-template #confirmationMessage>
            <div class="row d-flex align-items-center justify-content-center">
                <h3 class="text-center">{{l('ISponsorLaunchConfirmation')}}</h3>
                <div class="w-100 d-flex justify-content-center my-2">
                    <button type="button" class="btn btn-outline" (click)="close()">{{l('Close')}}</button>
                </div>
            </div>
        </ng-template>
    </span>
</div>