<div [@routerTransition] class="background" *ngIf="!isCheckout && !isOrderComplete">
    <div class="w-100" [hidden]="!fundraiserUserInfo">
        <div class="h-100 w-100">

            <fundraiser-header class="d-flex align-items-center justify-content-center w-100 header-container"
                               [organizationName]="fundraiserUserInfo?.organizationName"
                               [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
                               [fundraiserName]="fundraiserUserInfo?.fundraiserName"
                               [teamRaised]="teamRaised"
                               [effectiveTeamGoal]="effectiveTeamGoal"
                               [teamGoal]="teamGoal"
                               [teamGoalPercentActual]="teamGoalPercentActual"
                               [teamGoalPercentEffective]="teamGoalPercentEffective"
                               [fundraiserUserId]="fundraiserUserId"
                               [studentRaised]="studentRaised"
                               [isMetricDollars]="fundraiserUserInfo?.isMetricDollars"
                               [isLaunchathon]="true"
                               [isFundraiserOver]="isFundraiserOver && !viewSample"
                               [studentGoal]="studentGoal"
                               [effectiveStudentGoal]="effectiveStudentGoal"
                               [studentGoalPercentActual]="studentGoalPercentActual"
                               [studentGoalPercentEffective]="studentGoalPercentEffective"
                               [studentGoalRemaining]="studentGoalRemaining"
                               [studentName]="fundraiserUserInfo?.studentName"
                               [logoUrl]="fundraiserUserInfo?.logoUrl"
                               [goalValues]="goalValues">
            </fundraiser-header>

            <div class="mb-40 overlay d-flex justify-content-center">
                <div class="row middle-row reorder-mobile">
                    <help-reach-goal class="col-lg-6 countdown-container goal-reorder"
                                     [isFundraiserOver]="isFundraiserOver && !viewSample"
                                     [fundraiserUserId]="fundraiserUserId"
                                     [dayVsDays]="dayVsDays"
                                     [studentName]="fundraiserUserInfo?.studentName"
                                     [studentRaised]="studentRaised"
                                     [teamRaised]="teamRaised"
                                     [isMetricDollars]="true"
                                     [organizationName]="fundraiserUserInfo?.organizationName"
                                     [appListing]="appListing"
                                     [appListingSms]="appListingSms"
                                     [shareTitle]="shareTitle"
                                     [shareDescription]="shareDescription"
                                     [shareUrl]="shareUrl"
                                     [shareUrlSms]="shareUrlSms"
                                     [helpReachGoalInfo]="helpReachGoalInfo">
                    </help-reach-goal>

                    <div #selectDonationArea *ngIf="!isFundraiserOver || viewSample" class="col-lg-6 countdown-container donation-reorder">
                        <img src="/assets/common/images/help_tape.png" width="300" />
                        <div class="brush-stroke help-me-text">
                            {{l('SelectDonationAmount')}}
                        </div>
                        <div class="row donation-options-row px-10">
                            <div *ngIf="fundraiserUserId" class="col-6 col-sm-4 pointer" (click)="completeStudentGoal()">
                                <div class="row align-items-center donation-option" [ngStyle]="{'background-color': completeGoalDonationAmount ? '#8FCFA9' : 'white'}">
                                    <div class="col-1 plus-container">
                                        <div class="circle" [ngStyle]="{'background-color': completeGoalDonationAmount ? 'white' : '#8FCFA9'}">
                                            <div [ngClass]="completeGoalDonationAmount ? 'check fa fa-check' : 'plus fa fa-plus'"></div>
                                        </div>
                                    </div>
                                    <div class="col-11 donation-option-text">{{l('CompleteMyGoal')}}</div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 pointer" *ngFor="let donationOption of donationOptions" (click)="donationClick(donationOption)">
                                <div class="row align-items-center donation-option" [ngStyle]="{'background-color': donationAmount == donationOption ? '#8FCFA9' : 'white'}">
                                    <div class="col-1 plus-container">
                                        <div class="circle" [ngStyle]="{'background-color': donationAmount == donationOption ? 'white' : '#8FCFA9'}">
                                            <div [ngClass]="donationAmount == donationOption ? 'check fa fa-check' : 'plus fa fa-plus'"></div>
                                        </div>
                                    </div>
                                    <div class="col-11 donation-option-text">${{donationOption}}</div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 pointer" (click)="customDonationClick()">
                                <div class="row align-items-center donation-option" [ngStyle]="{'background-color': _customDonationAmount ? '#8FCFA9' : 'white'}">
                                    <div class="col-1 plus-container">
                                        <div class="circle" [ngStyle]="{'background-color': _customDonationAmount ? 'white' : '#8FCFA9'}">
                                            <div [ngClass]="customDonationAmount ? 'check fa fa-check' : 'plus fa fa-plus'"></div>
                                        </div>
                                    </div>
                                    <div class="col-11 custom-donation-container">
                                        <p-inputMask #donationAmountInput="ngModel" name="donationAmountInput"
                                                     mask="$99?999" [(ngModel)]="_customDonationAmount" placeholder="Custom" ngDefaultControl
                                                     class="form-control custom-input"
                                                     [ngClass]="_customDonationAmount ? 'is-custom-selected' : 'is-custom-not-selected'"
                                                     required data-lpignore="true">
                                        </p-inputMask>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" [ngClass]="fundraiserUserId ? 'col-sm-8' : ''">
                                <span style="font-style: italic;">{{l('DonationAmountNote')}}</span>
                            </div>
                            <div *ngIf="!donationAmount && !donationAmountInput.valid && donationAmountInput.dirty" class="validation-error">{{l('DonationMustBeAtLeastTenDollars')}}</div>
                        </div>
                    </div>

                    <div *ngIf="!isFundraiserOver || viewSample" class="col-12 your-support brush-stroke">
                        {{l('YourSupport')}}
                    </div>

                    <div class="col-lg-6 our-supporters-container">
                        <img src="/assets/common/images/help_tape.png" width="300" />
                        <div class="brush-stroke help-me-text">
                            {{isFundraiserOver && !viewSample ? l('ThanksToOurSupporters') : l('OurSupporters')}}
                        </div>
                        <div class="our-supporters" [ngStyle]="{'max-height': isFundraiserOver && !viewSample ? '250px' : '500px'}">
                            <supporters [orders]="fundraiserUserInfo?.orders">
                            </supporters>
                        </div>
                    </div>

                    <div *ngIf="!isFundraiserOver || viewSample" class="col-lg-6 countdown-container">
                        <about-the-team [aboutTheTeam]="fundraiserUserInfo?.aboutTheTeam"
                                        [aboutTheTeamVideo]="fundraiserUserInfo?.aboutTheTeamVideo">
                        </about-the-team>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center w-100">
                <div class="footer-organization-logo-container my-auto">
                    <img src="{{fundraiserUserInfo?.tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
                </div>
            </div>
            <div class="footer-links d-flex flex-wrap justify-content-center">
                <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Contact Us</a>
                <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Run your own Fundraiser</a>
                <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Join our team</a>
            </div>
            <div class="footer-links d-flex flex-wrap justify-content-center">
                <a (click)="openInNewTab(appUrlService.appRootUrl + 'TermsOfUse.html')">Terms of Use</a>
            </div>
            <div class="powered-by-row d-flex flex-row justify-content-center w-100">
                <div class="yellow-text d-flex align-items-end">
                    <div class="d-flex flex-column">
                        <div class="fundraiser-name-and-powered-by-container">
                            <div class="powered-by-container">
                                <div class="powered-by-text">{{l('PoweredBy')}}</div>
                                <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="live-link-footer fixed">
                <div *ngIf="!isFundraiserOver || viewSample" class="col-12 justify-content-center d-flex flex-row">
                    <button *ngIf="fundraiserUserInfo && !fundraiserUserInfo.isLaunchathon" type="button" class="customer-button customer-cancel-button"
                            (click)="navigateToProductFundraiserHomePage()" [disabled]="viewSample">
                        {{l("GoBack")}}
                    </button>
                    <div class="justify-content-center" (click)="submit()">
                        <button class="col customer-button customer-submit-button donation-option" [disabled]="!selectedDonationAmount || viewSample"
                                [ngClass]="selectedDonationAmount ? 'enabled-background' : 'disabled-background'">
                            {{ fundraiserUserInfo && fundraiserUserInfo.isLaunchathon ? l("Donate") : l("CheckOut") }}
                        </button>
                    </div>
                </div>
            </footer>
            <div *ngIf="!fundraiserUserInfo?.fundraiserName">
                {{l('FundraiserNotFound')}}
            </div>
        </div>
    </div>
    <button *ngIf="(!isFundraiserOver || viewSample) && pageYoffset > 700" 
            class="scroll-to-top-btn" 
            [disabled]="viewSample"
            (click)="scrollToDonationOptions()">Donate</button>
</div>

<ng-container *ngIf="isCheckout">

    <checkout-v2 *ngIf="fundraiserUserInfo.isBetaCheckout"
        [donationAmountInput]="selectedDonationAmount"
        [fundraiserUserIdInput]="fundraiserUserId"
        [fundraiserIdInput]="fundraiserUserInfo?.fundraiserId"
        [fundraiserName]="fundraiserUserInfo?.fundraiserName"
        [logoUrl]="fundraiserUserInfo?.logoUrl"
        [organizationName]="fundraiserUserInfo?.organizationName"
        [availableSellers]="fundraiserUserInfo?.availableSellers"
        [teamMemberIdInput]="teamMemberId"
        [teamMemberNameInput]="fundraiserUserInfo?.studentName"
        [isGuest]="fundraiserUserInfo?.isGuest"
        [shareLink]="getShareLink()"
        [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
        [guestPayMode]="2"
        [orderDetailId]="orderDetailId"
        [orderDetail]="undefined"
        [isInitialLoadForEdit]="true"
        [isLaunchathon]="true"
        [isCompleteGoal]="this.completeGoalDonationAmount"
        [fundraiserStudentContactSlug]="fundraiserStudentContactSlug"
        [automatedMessageType]="automatedMessageType"
        [tipInfoTitle]="fundraiserUserInfo?.tipInfoTitle"
        [tipInfo]="fundraiserUserInfo?.tipInfo"
        [tenantId]="tenantId"
        [isApplePayEnabledForFundraiser]="isApplePayEnabledForFundraiser"
        [isISponsorEnabled]="isISponsorEnabledForFundraiser"
        (orderComplete)="orderComplete($event)"
        (cancelOrder)="cancelOrder()"
        (goBack)="reload($event)"></checkout-v2> 
        
    <checkout-launchathon *ngIf="!fundraiserUserInfo.isBetaCheckout"
                          [donationAmountInput]="selectedDonationAmount"
                          [fundraiserUserIdInput]="fundraiserUserId"
                          [fundraiserIdInput]="fundraiserUserInfo?.fundraiserId"
                          [fundraiserName]="fundraiserUserInfo?.fundraiserName"
                          [logoUrl]="fundraiserUserInfo?.logoUrl"
                          [organizationName]="fundraiserUserInfo?.organizationName"
                          [availableSellers]="fundraiserUserInfo?.availableSellers"
                          [teamMemberIdInput]="teamMemberId"
                          [teamMemberNameInput]="fundraiserUserInfo?.studentName"
                          [isGuest]="fundraiserUserInfo?.isGuest"
                          [shareLink]="getShareLink()"
                          [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
                          [guestPayMode]="2"
                          [orderDetailId]="orderDetailId"
                          [orderDetail]="undefined"
                          [isInitialLoadForEdit]="true"
                          [isCompleteGoal]="this.completeGoalDonationAmount"
                          [fundraiserStudentContactSlug]="fundraiserStudentContactSlug"
                          [automatedMessageType]="automatedMessageType"
                          [tipInfoTitle]="fundraiserUserInfo?.tipInfoTitle"
                          [tipInfo]="fundraiserUserInfo?.tipInfo"
                          [isISponsorEnabled]="isISponsorEnabledForFundraiser"
                          (orderComplete)="orderComplete($event)"
                          (cancelOrder)="cancelOrder()"
                          (goBack)="reload($event)"></checkout-launchathon>
</ng-container>

<order-confirmation #orderConfirmation [hidden]="!isOrderComplete"
                    [customerEmailAddressInput]="customerEmailAddress"
                    [appListing]="appListing"
                    [appListingSms]="appListingSms"
                    [shareTitle]="shareTitle"
                    [shareDescription]="shareDescription"
                    [shareUrl]="shareUrl"
                    [shareUrlSms]="shareUrlSms"
                    [fundraiserId]="fundraiserUserInfo?.fundraiserId"
                    [isLaunchathon]="true"
                    [orderTransactionId]="orderTransactionId"
                    [isISponsorEnabled]="isISponsorEnabledForFundraiser"
                    (returnToFundraiserHomeEmitter)="returnFromConfirmation()"></order-confirmation>
