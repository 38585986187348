<div [@routerTransition] class="background" *ngIf="!isCheckout && !isOrderComplete">
    <div class="w-100" [hidden]="!fundraiserUserInfo">
        <div class="h-100 w-100">

            <fundraiser-header class="d-flex align-items-center justify-content-center w-100 header-container"
                               [organizationName]="fundraiserUserInfo?.organizationName"
                               [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
                               [fundraiserName]="fundraiserUserInfo?.fundraiserName"
                               [teamRaised]="teamRaised"
                               [effectiveTeamGoal]="effectiveTeamGoal"
                               [teamGoal]="teamGoal"
                               [teamGoalPercentActual]="teamGoalPercentActual"
                               [teamGoalPercentEffective]="teamGoalPercentEffective"
                               [fundraiserUserId]="fundraiserUserId"
                               [studentRaised]="studentRaised"
                               [isMetricDollars]="fundraiserUserInfo?.isMetricDollars"
                               [isLaunchathon]="true"
                               [isFundraiserOver]="isFundraiserOver && !viewSample"
                               [studentGoal]="studentGoal"
                               [effectiveStudentGoal]="effectiveStudentGoal"
                               [studentGoalPercentActual]="studentGoalPercentActual"
                               [studentGoalPercentEffective]="studentGoalPercentEffective"
                               [studentGoalRemaining]="studentGoalRemaining"
                               [studentName]="fundraiserUserInfo?.studentName"
                               [logoUrl]="fundraiserUserInfo?.logoUrl"
                               [goalValues]="goalValues">
            </fundraiser-header>

            <div class="mb-40 overlay d-flex justify-content-center">
                <div class="row middle-row reorder-mobile">
                    <help-reach-goal class="col-lg-6 countdown-container goal-reorder"
                                     [isFundraiserOver]="isFundraiserOver && !viewSample"
                                     [fundraiserUserId]="fundraiserUserId"
                                     [dayVsDays]="dayVsDays"
                                     [studentName]="fundraiserUserInfo?.studentName"
                                     [studentRaised]="studentRaised"
                                     [teamRaised]="teamRaised"
                                     [isMetricDollars]="true"
                                     [organizationName]="fundraiserUserInfo?.organizationName"
                                     [appListing]="appListing"
                                     [appListingSms]="appListingSms"
                                     [shareTitle]="shareTitle"
                                     [shareDescription]="shareDescription"
                                     [shareUrl]="shareUrl"
                                     [shareUrlSms]="shareUrlSms"
                                     [helpReachGoalInfo]="helpReachGoalInfo">
                    </help-reach-goal>
                    
                    <div #selectDonationArea *ngIf="!isFundraiserOver || viewSample" class="col-lg-6 donation-reorder">
                        <div class="calendar calendar-container">
                            <div class="title form-floating">
                                <img src="/assets/common/images/help_tape.png" />
                                <div class="brush-stroke help-me-text text-center">
                                    {{l('CalendarFundraiser')}}
                                </div>
                            </div>
                            <div class="col-12 col-md-6" *ngIf="fundraiserUserInfo">
                                <h5>
                                    {{l('CalendarFundraiser.WhomSupporting')}}
                                    <span class="text-danger" *ngIf="!fundraiserUserId">*</span>
                                </h5>
                                <select #teamMemberSelect="ngModel" [name]="l('CalendarFundraiser.SelectName')" [title]="l('CalendarFundraiser.SelectName')" (ngModelChange)="changeStudent($event)"
                                        class="form-control" [ngClass]="{'team-member-required': !fundraiserUserId}" [(ngModel)]="fundraiserUserId" [disabled]="isStudentSelectDisabled" required>
                                    <option [ngValue]="null" disabled>{{l('CalendarFundraiser.SelectName')}}</option>
                                    <option value="-1">Other</option>
                                    <option *ngFor="let teamMember of fundraiserUserInfo?.availableSellers" [value]="teamMember.fundraiserUserId">{{teamMember.name}}</option>
                                </select>
                                <validation-messages [formCtrl]="teamMemberSelect"></validation-messages>
                            </div>

                            <div class="row inputs-column mb-2 mt-3">
                                <ng-container *ngIf="this.fundraiserUserId == this.writeInSellerTeamMemberId">
                                    <div class="col-6">
                                        <div class="label-height">{{ writeInSellerFirstName ? l('TeamMemberFirstName') : '' }}</div>
                                        <input #teamMemberFirstNameInput="ngModel" name="{{l('TeamMemberFirstName')}}"
                                               type="text" [(ngModel)]="this.writeInSellerFirstName" placeholder="{{l('TeamMemberFirstName')}}"
                                               required min="1" max="100" data-lpignore="true"  />
                                        <validation-messages [formCtrl]="teamMemberFirstNameInput"></validation-messages>
                                    </div>
                                    <div class="col-6">
                                        <div class="label-height">{{ writeInSellerLastName ? l('TeamMemberLastName') : '' }}</div>
                                        <input #teamMemberLastNameInput="ngModel" name="{{l('TeamMemberLastName')}}"
                                               type="text" [(ngModel)]="this.writeInSellerLastName" placeholder="{{l('TeamMemberLastName')}}"
                                               required min="1" max="100" data-lpignore="true" />
                                        <validation-messages [formCtrl]="teamMemberLastNameInput"></validation-messages>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="how-it-works">
                                <h4>{{l('CalendarFundraiser.HowItWorks')}}</h4>
                                <div>
                                    <span class="bullet">1</span>
                                    <span class="ml-1">{{l('CalendarFundraiser.HowItWorks1')}}</span>
                                </div>
                                <div>
                                    <span class="bullet">2</span>
                                    <span>{{l('CalendarFundraiser.HowItWorks2')}}</span>
                                </div>
                                <div>
                                    <span class="bullet">3</span>
                                    <span>{{l('CalendarFundraiser.HowItWorks3')}}</span>
                                </div>
                            </div>
                            <div class="month-previous-next mb-2 d-flex justify-content-center">
                                <div class="d-flex align-items-center justify-content-between w-100">
                                    <div class="d-flex align-items-center">
                                        <i class="fa fa-arrow-alt-circle-left previous" [ngClass]="{'previous-next': canGoPreviousMonth(), 'previous-next-disabled': !canGoPreviousMonth() }" (click)="canGoPreviousMonth() && goToMonth(currentMonth.month-1)"></i>
                                        <span class="text-md-start ms-2 fw-bolder">Prev</span>
                                    </div>
                                    <h1 class="text-md-center flex-grow-1 text-center">{{calendarHeader}}</h1>
                                    <div class="d-flex align-items-center">
                                        <span class="text-md-end me-2 fw-bolder">Next</span>
                                        <i class="fa fa-arrow-alt-circle-right next" [ngClass]="{'previous-next': canGoNextMonth(), 'previous-next-disabled': !canGoNextMonth() }" (click)="canGoNextMonth() && goToMonth(currentMonth.month+1)"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="month">
                                <div class="row justify-content-center mb-4" *ngIf="allQuickSelections.length">
                                    <span class="quickSelection p-2 px-3" [ngClass]="{'selected': finishQuickSelection.selected, 'disabled': finishQuickSelection.disabled }"
                                          (click)="toggleQuickSelection(finishQuickSelection)">{{finishQuickSelection.title}}: {{finishQuickSelection.totalRemaining | currency}}</span>
                                </div>
                                <div class="row justify-content-center" *ngIf="allQuickSelections.length">
                                    <span class="quickSelection p-2 px-3" [ngClass]="{'selected': evenQuickSelection.selected, 'disabled': evenQuickSelection.disabled }"
                                          (click)="toggleQuickSelection(evenQuickSelection)">{{evenQuickSelection.title}}: {{evenQuickSelection.totalRemaining | currency}}</span>
                                    <span class="quickSelection ms-2 p-2 px-3" [ngClass]="{'selected': oddQuickSelection.selected, 'disabled': oddQuickSelection.disabled }"
                                          (click)="toggleQuickSelection(oddQuickSelection)">{{oddQuickSelection.title}}: {{oddQuickSelection.totalRemaining | currency}}</span>
                                </div>
                                <div class="row mt-4 mb-2">
                                    <div *ngFor="let dayQs of dayQuickSelections" class="dayCell">
                                        <span class="dayHdr quickSelection" [ngClass]="{'selected': dayQs.selected, 'disabled': dayQs.disabled }"
                                              (click)="toggleQuickSelection(dayQs)">{{dayQs.title}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="dayCell" *ngFor="let day of currentMonth.days">
                                        <ng-template #tooltipTemplate>
                                            <span *ngFor="let supporter of getDaySupportersTooltip(day)">{{supporter}}<br></span>
                                        </ng-template>
                                        <ng-template #noTooltip>
                                            <div class="day" [ngClass]="{'selectedDay': day.selected, 'disabledDonation': isDisabledDay(day) }" (click)="toggleDay(day, null)">
                                                <ng-container *ngTemplateOutlet="dayNumberTemplate; context: { $implicit: day }"></ng-container>
                                            </div>
                                        </ng-template>                                        
                                        <ng-template #dayNumberWithTooltipTemplate let-day>
                                            <!-- the property 'triggers' must be set to "" to switch the triggers for the tooltip to manual. (click) -->
                                            <div class="day" 
                                                [ngClass]="{'selectedDay': day.selected, 'disabledDonation': isDisabledDay(day) }"
                                                [tooltip]="tooltipTemplate"
                                                placement="bottom"
                                                triggers=""
                                                containerClass="mt-n4"
                                                (click)="ShowHideTooltips(tooltipCalendar);"
                                                #tooltipCalendar="bs-tooltip">
                                                <ng-container *ngTemplateOutlet="dayNumberTemplate; context: { $implicit: day }"></ng-container>
                                            </div>
                                        </ng-template>
                                        <ng-template #dayNumberTemplate let-day>
                                            <span class="dayNumber">{{getDayText(day)}}</span>
                                        </ng-template>

                                        <ng-container *ngIf="getDaySupportersTooltip(day).length > 0; else noTooltip">
                                            <ng-container *ngTemplateOutlet="dayNumberWithTooltipTemplate; context: { $implicit: day }"></ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="dayCell" *ngFor="let donation of currentMonth.donations">
                                        <div class="donation" [ngClass]="{'selectedDonation': donation.selected, 'disabledDonation': !teamMemberId}" (click)="toggleDonation(donation)">${{donation.dollarAmount}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isFundraiserOver || viewSample" class="col-12 your-support brush-stroke">
                        {{l('YourSupport')}}
                    </div>

                    <div class="col-lg-6 our-supporters-container">
                        <img src="/assets/common/images/help_tape.png" width="300" />
                        <div class="brush-stroke help-me-text">
                            {{isFundraiserOver && !viewSample ? l('ThanksToOurSupporters') : l('OurSupporters')}}
                        </div>
                        <div class="our-supporters" [ngStyle]="{'max-height': isFundraiserOver && !viewSample ? '250px' : '500px'}">
                            <supporters [orders]="fundraiserUserInfo?.orders">
                            </supporters>
                        </div>
                    </div>

                    <div *ngIf="!isFundraiserOver || viewSample" class="col-lg-6 countdown-container">
                        <about-the-team [aboutTheTeam]="fundraiserUserInfo?.aboutTheTeam"
                                        [aboutTheTeamVideo]="fundraiserUserInfo?.aboutTheTeamVideo">
                        </about-the-team>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center w-100">
                <div class="footer-organization-logo-container my-auto">
                    <img src="{{fundraiserUserInfo?.tenantLogoUrl}}" alt="logo" class="hour-a-thon-logo">
                </div>
            </div>
            <div class="footer-links d-flex flex-wrap justify-content-center">
                <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Contact Us</a>
                <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Run your own Fundraiser</a>
                <a (click)="openInNewTab('https://launchfundraising.com/#contact')">Join our team</a>
            </div>
            <div class="footer-links d-flex flex-wrap justify-content-center">
                <a (click)="openInNewTab(appUrlService.appRootUrl + 'TermsOfUse.html')">Terms of Use</a>
            </div>
            <div class="powered-by-row d-flex flex-row justify-content-center w-100">
                <div class="yellow-text d-flex align-items-end">
                    <div class="d-flex flex-column">
                        <div class="fundraiser-name-and-powered-by-container">
                            <div class="powered-by-container">
                                <div class="powered-by-text">{{l('PoweredBy')}}</div>
                                <img alt="Launch Logo" src="/assets/common/images/logo-launch.png" class="powered-by-logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="live-link-footer fixed">
                <div *ngIf="!isFundraiserOver || viewSample" class="col-12 justify-content-center d-flex flex-row">   
                    <div class="justify-content-center">
                        <div class="donationInfo">
                            <div class="donationTotal">{{l("Total")}}: {{donationAmount | currency}}</div>
                            <div class="minimumNotMet" *ngIf="donationAmount < minimumDonation">{{l("CalendarFundraiser.MinimumNotMet")}}</div>
                        </div>
                        <button (click)="submit()" type="button" style="float: left" class="col customer-button customer-submit-button donation-option" >
                            {{ l("CheckOut") }}
                        </button>
                    </div>
                </div>
            </footer>
            <div *ngIf="!fundraiserUserInfo?.fundraiserName">
                {{l('FundraiserNotFound')}}
            </div>
        </div>
    </div>
    <button *ngIf="(!isFundraiserOver || viewSample) && pageYoffset > 700" 
            class="scroll-to-top-btn" 
            [disabled]="viewSample"
            (click)="scrollToDonationOptions()">Donate</button>
</div>

<ng-container *ngIf="isCheckout">

    <checkout-v2
        [donationAmountInput]="donationAmount"
        [fundraiserUserIdInput]="fundraiserUserId"
        [fundraiserIdInput]="fundraiserUserInfo?.fundraiserId"
        [fundraiserName]="fundraiserUserInfo?.fundraiserName"
        [logoUrl]="fundraiserUserInfo?.logoUrl"
        [organizationName]="fundraiserUserInfo?.organizationName"
        [availableSellers]="fundraiserUserInfo?.availableSellers"
        [teamMemberIdInput]="teamMemberId"
        [teamMemberNameInput]="getStudentName()"
        [writeInSellerFirstName]="writeInSellerFirstName"
        [writeInSellerLastName]="writeInSellerLastName"
        [isGuest]="fundraiserUserInfo?.isGuest"
        [shareLink]="getShareLink()"
        [tenantLogoUrl]="fundraiserUserInfo?.tenantLogoUrl"
        [guestPayMode]="2"
        [orderDetailId]="orderDetailId"
        [orderDetail]="undefined"
        [isInitialLoadForEdit]="true"
        [isLaunchathon]="true"
        [isCalendar]="true"
        [isCompleteGoal]=0
        [fundraiserStudentContactSlug]="fundraiserStudentContactSlug"
        [automatedMessageType]="automatedMessageType"
        [tipInfoTitle]="fundraiserUserInfo?.tipInfoTitle"
        [tipInfo]="fundraiserUserInfo?.tipInfo"
        [tenantId]="tenantId"
        [isApplePayEnabledForFundraiser]="isApplePayEnabledForFundraiser"
        [isISponsorEnabled]="isISponsorEnabledForFundraiser"
        (orderComplete)="orderComplete($event)"
        (cancelOrder)="cancelOrder()"
        (goBack)="reload($event)"></checkout-v2> 

</ng-container>

<order-confirmation #orderConfirmation [hidden]="!isOrderComplete"
                    [customerEmailAddressInput]="customerEmailAddress"
                    [appListing]="appListing"
                    [appListingSms]="appListingSms"
                    [shareTitle]="shareTitle"
                    [shareDescription]="shareDescription"
                    [shareUrl]="shareUrl"
                    [shareUrlSms]="shareUrlSms"
                    [fundraiserId]="fundraiserUserInfo?.fundraiserId"
                    [isLaunchathon]="true"
                    [orderTransactionId]="orderTransactionId"
                    [isISponsorEnabled]="isISponsorEnabledForFundraiser"
                    (returnToFundraiserHomeEmitter)="returnFromConfirmation()"></order-confirmation>
